<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Septiembre 2023                                                 ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <!-- Descripción -->
        <div class="d-flex align-center mb-3">
            <v-icon color="blue darken-4" size="55">text_snippet</v-icon>
            <span class="ms-4 descripcion">
                Herramienta diseñada para generar reportes en formato CSV 
                <br> de los paquetes entregados por la transportadora en un periodo de hasta un mes.
            </span>
        </div>
        <v-divider color="#9E9E9" class="mb-5"></v-divider>

        <!-- Fitros -->
        <section>
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-row>
                    <!-- Filtrar por fecha inicial -->
                    <v-col cols="12" sm="6" md="3">
                        <v-menu ref="menuFechaInicio" v-model="menuFechaInicio" transition="scale-transition" min-width="290px"
                            :close-on-content-click="false" :return-value.sync="filtro.fechaInicio" :nudge-top="25" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <validation-provider ref="fechaInicialValidation" v-slot="{ errors }" :rules="{ required: true}">
                                    <v-text-field v-model="filtro.fechaInicio" label="Fecha inicial" v-bind="attrs" v-on="on" :error-messages="errors"  
                                        outlined dense readonly>
                                    </v-text-field>
                                </validation-provider>
                            </template>
                            <v-date-picker v-model="filtro.fechaInicio" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="filtro.fechaInicio = ''">Borrar</v-btn>
                                <v-btn text color="primary" @click="$refs.menuFechaInicio.save(filtro.fechaInicio); validacionesFechas()">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>

                    <!-- Filtrar por fecha final -->
                    <v-col cols="12" sm="6" md="3">
                        <v-menu ref="menuFechaFinal" v-model="menuFechaFinal" transition="scale-transition" min-width="290px"
                            :close-on-content-click="false" :return-value.sync="filtro.fechaFin" :nudge-top="25" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <validation-provider ref="fechaFinalValidation" v-slot="{ errors }" :rules="{ required: true}">
                                    <v-text-field v-model="filtro.fechaFin" label="Fecha final" v-bind="attrs" v-on="on" 
                                        :error-messages="rangoFechas|| instruccionFechas || errors" outlined dense readonly>
                                    </v-text-field>
                                </validation-provider>
                            </template>
                            <v-date-picker v-model="filtro.fechaFin" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="filtro.fechaFin = ''">Borrar</v-btn>
                                <v-btn text color="primary" @click="$refs.menuFechaFinal.save(filtro.fechaFin); validacionesFechas()">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>

                    <!-- Filtrar por tipo de transacción-->
                    <v-col cols="12" sm="6" md="3">
                        <validation-provider ref="tipoTransaccionValidation" v-slot="{ errors }" :rules="{ required: true}">
                            <v-select v-model="filtro.transaccion" label="Tipo de transacción" :error-messages="errors"
                                :items="tipoTransaccion" :menu-props="{ offsetY: true }" outlined dense>
                            </v-select>
                        </validation-provider>
                    </v-col>
                    
                    <!-- Filtrar por NIT de la transportadora-->
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="filtro.nitTransportadora" label="NIT transportadora" 
                            @keypress="validarNumeros" outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- Botón de generar reporte -->
                <v-sheet class="d-flex align-center justify-center" :color="invalid ? 'grey lighten-4' : 'green lighten-5'" height="65px" width="100%" rounded>
                    <v-btn color="success" :disabled="invalid" @click="listarTracking()">
                        <v-icon>download</v-icon>
                        generar reporte
                    </v-btn>
                </v-sheet>
            </validation-observer>
        </section>

        <!-- Alerta cuando no se encuentran resultados -->
        <v-alert  class="mt-5" icon="error" color="error" transition="scale-transition" :value="alert" outlined>
            <h3>No se encontraron resultados.</h3>
            <p class="no-resultados">Los datos ingresados no coinciden con ningún registro.
            <br>Verifica que los filtros sean correctos o intenta una búsqueda diferente.</p>
        </v-alert>

    </div>
</template>
 <!-- #################################################################################### -->
 <!-- ###### Sección de Scripts                                                     ###### -->
 <!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
import Papa from 'papaparse'

/**
 * Esta función toma una matriz de matrices (una matriz bidimensional) 
 * donde cada sub-matriz (o fila) representa una fila de datos en el CSV.
 * Cada elemento dentro de las sub-matrices se une con un delimitador 
 * (en este caso un punto y coma ';') y cada fila se une con un salto de línea.
 * 
 * @param {Array} data - Matriz bidimensional que contiene los datos.
 * @returns {string} - Cadena representando los datos en formato CSV.
 */
function arrayToCSV(data) {
    return data.map(row => row.join(";")).join("\n");
}

/**
 * Esta función crea un archivo en formato CSV a partir de una cadena 
 * y luego inicia una descarga en el navegador del usuario para ese archivo.
 * 
 * @param {string} csvData - Cadena que contiene los datos en formato CSV.
 * @param {string} filename - Nombre que se asignará al archivo descargado.
 */
function downloadCSV(csvData, filename) {
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});
export default {
    name: "ReportesTracking",
    props: {
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            alert: false,
            rangoFechas: '',
            instruccionFechas: '',
            menuFechaInicio: false,
            menuFechaFinal: false,
            filtro: {
                fechaInicio: '',
                fechaFin: '',
                transaccion: '',
                nitTransportadora: '',
            },
            tipoTransaccion: [
                { text: 'Cliente', value: 'C' },
                { text: 'Domicilio', value: 'D' },
                { text: 'Traslado', value: 'I' },
            ],
            mensajeError: '',
            userRoles: {},
            roles: Role,
        }
    },
    created() {
        this.userRoles = this.auth.roles;
    },
    computed: {
        ...mapState(["auth", "notify", "busy"]),
        /**
         * Función computada que devuelve la fecha actual en formato ISO (YYYY-MM-DD)
         * para que en el v-date-picker no seleccione fechas posteriores a las del día actual. 
         */
        currentDate() {
            return new Date().toISOString().split('T')[0];
        },
    },
    watch: {  
        /**
         * Este watcher se activa cuando alguna propiedad del objeto `filtro` cambia.
         * Es "deep", por lo que observa cambios en propiedades anidadas dentro de `filtro`.
         * Al detectar cambios, se encarga de desactivar la alerta (`alert` se establece en false).
         */
        filtro: {
            deep: true,
            handler() {
                this.alert = false;
            }
        }
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        /**
         * Método para validar la entrada de caracteres en un campo de texto.
         * Este método se encarga de asegurar que solo se permita la entrada de caracteres numéricos (0-9).
         * Si se detecta una tecla que no es un número, se previene su ingreso mediante el método preventDefault().
         * @param {*} event - El objeto de evento que representa la tecla presionada por el usuario.
         */
        validarNumeros(event) {
            const key = event.key;
            if (!((key >= '0' && key <= '9'))) {
                event.preventDefault();
            }
        },
        /**
         * Solicita y procesa los datos de los paquetes entregados basándose en los filtros.
         * Este método obtiene un conjunto de datos relacionados con los paquetes entregados 
         * por la transportadora. La respuesta esperada es un blob que contiene datos en formato CSV. Estos datos son luego 
         * procesados y convertidos para su descarga.
         */
        listarTracking() {
            this.$http
            .get(`msa-reports/encabezadoTracking/listarTracking`, {
                responseType: 'blob',
                params: {
                    tipoTransaccion: this.filtro.transaccion,
                    fechaInicial: this.filtro.fechaInicio,
                    fechaFinal: this.filtro.fechaFin,
                    nitTransportadora: this.filtro.nitTransportadora
                }
            }).then(response => {
                const filename = `reporte_entregas_${this.filtro.fechaInicio.replace(/-/g, '_')}_al_${this.filtro.fechaFin.replace(/-/g, '_')}.csv`;
                Papa.parse(response.data, {
                    complete: (results) => {
                        const csvData = arrayToCSV(results.data);
                        if(results.data.length > 2) {
                            downloadCSV(csvData, filename);
                            this.clear();
                        } else {
                            this.alert = true;
                        }
                    }
                });
            }).catch(error => {
                console.log(error);
            })
        },
        /**
         * Este método verifica que la fecha inicial (`fechaUno`) sea anterior a la fecha final (`fechaDos`). Además, 
         * se asegura de que el rango de fechas seleccionadas no exceda un mes. Si alguna de estas condiciones no se cumple, 
         * establece un mensaje de error correspondiente y marca el estado como inválido (`invalid = true`).
         */
        validacionesFechas() {
            const fechaUno = new Date(this.filtro.fechaInicio);
            const fechaDos = new Date(this.filtro.fechaFin);

            const rangoUnMes = new Date(fechaUno);
            rangoUnMes.setMonth(fechaUno.getMonth() + 1);
            
            if (fechaUno > fechaDos) {
                this.instruccionFechas = 'La fecha final debe ser mayor a la fecha inicial';
                this.invalid = true;
            } else if (fechaDos > rangoUnMes) {
                this.rangoFechas = 'Las fechas seleccionadas no deben exceder un mes'
                this.invalid = true;
            } else {
                this.instruccionFechas = '';
                this.rangoFechas = '';
            }
        },
        /**
         * Limpia y reinicia el estado del formulario y sus validaciones.
         * Este método resetea las validaciones del formulario mediante el observer de VeeValidate.
         * Además, se encarga de limpiar las fechas seleccionadas, el tipo de transacción, el NIT de la transportadora y 
         * cualquier mensaje de error asociado a las fechas.
         */
        clear() {
            this.$refs.observer.reset();
            this.filtro.fechaFin = '';
            this.filtro.fechaInicio = '';
            this.filtro.transaccion = '';
            this.filtro.nitTransportadora = '';
            this.instruccionFechas = '';
            this.rangoFechas = '';
        }
    }
}
</script>
 <!-- #################################################################################### -->
 <!-- ###### Sección de Estilos                                                     ###### -->
 <!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    width: 100%;
    height: 86vh;
}
.descripcion {
    color: rgb(64, 62, 62); 
}
.no-resultados{
    font-size: 15px;
    margin: 0;
}
</style>
